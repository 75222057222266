<template>
	<BaseLoading v-if="isLoading" />
	<div v-else>
		<CRow>
			<CCol class="text-right">
				<router-link
					:to="{ name: 'KTCInstallmentPlanCreate' }"
					class="btn btn-secondary"
				>
					<CIcon name="cil-plus" class="mr-2" />
					Create plan
				</router-link>
			</CCol>
		</CRow>
		<!-- Filter -->
		<CRow class="mt-4">
			<CCol class="d-flex align-items-start">
				<form
					class="search-form flex-fill"
					@submit.prevent="handleSearch"
				>
					<CInput
						v-model.trim="queryParams.q"
						placeholder="Search by name, ID"
					>
						<template #prepend-content>
							<CIcon name="cil-magnifying-glass" />
						</template>
					</CInput>
				</form>
				<BaseFilterButton
					class="ml-3"
					:is-open="isShowFilter"
					:has-filter="hasFilter"
					@onClick="toggleFilter"
				/>
			</CCol>
		</CRow>
		<CRow>
			<CCol>
				<transition name="fade">
					<div
						v-if="isShowFilter"
						class="filter-box rounded-sm pt-3 px-3 mb-3"
					>
						<CRow>
							<CCol md="3">
								<BaseInputDate
									:value="dateRange"
									label="Date"
									placeholder="All periods"
									mode="range"
									@input="handleFilterDateChange"
								>
									<template #append-content>
										<CIcon class="cil-calendar" name="cil-calendar" />
									</template>
								</BaseInputDate>
							</CCol>
							<CCol md="3">
								<BaseDropDown
									v-model="filter.period"
									:searchable="false"
									:options="INSTALLMENT_PLAN_PERIODS_OPTIONS"
									:allow-empty="false"
									class="select-custom"
									label="name"
									track-by="value"
									label-drop-down="Period status"
									@input="handlePeriodStatus"
								/>
							</CCol>
						</CRow>
					</div>
				</transition>
			</CCol>
		</CRow>
		<CRow class="mb-4">
			<CCol>
				<BaseSearchFormFooter
					:count="meta.total"
					append-text="plan(s) found"
					@onReset="resetFilter"
				/>
			</CCol>
		</CRow>
		<!-- End Filter -->

		<!-- Table -->
		<CRow>
			<CCol>
				<BaseTable
					:is-loading="isLoading"
					:pagination="{
						pages: meta.lastPage,
						activePage: meta.currentPage
					}"
					:fields="INSTALLMENT_PLAN_TABLE_FIELDS"
					:items="dataTable"
					clickable-rows
					class="table-custom"
					@onRowClick="linkToKTCInstallmentPlanEdit"
					@onPaginationClick="handlePageChange"
				>
					<template #no-items-view>
						<div class="empty-table-element">
							<p class="subtitle">
								{{ $t("global.searchNotFound", { field: "plans" }) }}
							</p>
						</div>
					</template>
					<template #interestRate="{item}">
						{{ item }}%
					</template>
					<template #periodStatus="{item}">
						<CBadge class="badge-status" :color="item.color">
							{{ item.title }}
						</CBadge>
					</template>
					<template #period="{item}">
						{{ item }} month(s)
					</template>
					<template #availableSkus="{item}">
						{{ item | numberFormat }} SKU(s)
					</template>
					<template #minOrderAmount="{item}">
						<span v-if="item">{{ item | priceFormat }}</span>
						<span v-else>-</span>
					</template>
					<template #maxOrderAmount="{item}">
						<span v-if="item">{{ item | priceFormat }}</span>
						<span v-else>-</span>
					</template>
					<template #supplierId="{item}">
						{{ item }}
					</template>
					<template #planId="{item}">
						<CLink
							variant="ghost"
							color="secondary"
							@click.stop="handleDeletePlan(item)"
						>
							Delete
						</CLink>
					</template>
				</BaseTable>
			</CCol>
		</CRow>
		<!-- End Table -->
		<BaseModalConfirmDelete
			ref="modal-remove"
			:handle-remove="deleteInstallmentPlan.bind(null, { methodId: this.$route.params.methodId, planId: selectedPlanId })"
			title="Remove this installment plan?"
			description="By removing this, the installment plan and condition will be removed from the system and will no longer available for customers on the Studio7 website."
			@onSuccess="redirectToPaymentSetting"
		/>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import {
	INSTALLMENT_PLAN_TABLE_FIELDS,
	INSTALLMENT_PLAN_PERIODS_OPTIONS,
} from '../enums/ktcInstallmentPlans';
import {
	randomSearchString,
	cleanObject,
	cloneDeep,
	hasSearchFilter,
	convertDateRangeToUTC,
	datetimeFormat,
} from '../assets/js/helpers';

export default {
	name: 'KTCInstallmentPlanList',
	components: {},
	data() {
		return {
			INSTALLMENT_PLAN_TABLE_FIELDS,
			INSTALLMENT_PLAN_PERIODS_OPTIONS,
			// query params from url
			queryParams: {
				q: this.$route.query.q || null,
				page: Number(this.$route.query.page) || null,
				started_at: this.$route.query.started_at || null,
				ended_at: this.$route.query.ended_at || null,
				period_status: this.$route.query.period_status || null,
				r: randomSearchString(),
			},
			filter: {
				period: this.getSelectedValue(
					this.$route.query.period_status,
					INSTALLMENT_PLAN_PERIODS_OPTIONS,
				),
			},
			isShowFilter: false,
			selectedPlanId: null,
		};
	},

	computed: {
		...mapState('payments', {
			list: 'installment',
		}),

		isLoading() {
			return this.list.isLoading;
		},
		meta() {
			return this.list.meta;
		},
		hasFilter() {
			return hasSearchFilter(this.queryParams);
		},
		dateRange() {
			if (!this.queryParams.started_at || !this.queryParams.ended_at) {
				return null;
			}

			return {
				start: new Date(this.queryParams.started_at),
				end: new Date(this.queryParams.ended_at),
			};
		},
		// mapping for data table
		dataTable() {
			return this.list.data.map((item) => ({
				id: item.id || null,
				name: item.name || null,
				availableSkus: item.availableSkus || 0,
				interestRate: item.interestRate || 0,
				minOrderAmount: item.minOrderAmount || null,
				maxOrderAmount: item.maxOrderAmount || null,
				startDate: datetimeFormat(item.startDate) || null,
				endDate: datetimeFormat(item.endDate) || null,
				period: item.period || 0,
				periodStatus: item.periodStatus || null,
				planId: item.planId,
				// Custom attribute for
				supplierId: item.customAttributes.supplierId,
			}));
		},
	},

	watch: {
		list: {
			deep: true,
			handler(newVal) {
				const bankName = newVal.data[0]?.paymentMethod.name ? newVal.data[0]?.paymentMethod.name : '';
				this.setBreadcrumbs([
					{
						title: 'Payment settings',
						route: { name: 'PaymentList' },
					},
					{
						title: `Installment credit card ${bankName}`,
						route: {
							name: 'PaymentMethodSetting',
							params: { id: this.$route.params.id, methodId: this.$route.params.methodId },
						},
					},
				]);
			},
		},
	},

	async created() {
		this.isShowFilter = this.hasFilter;
		this.fetchData();
	},

	methods: {
		...mapActions({
			getInstallmentPlans: 'payments/getInstallmentPlans',
			deleteInstallmentPlan: 'payments/deleteInstallmentPlan',
			setBreadcrumbs: 'breadcrumb/setBreadcrumbs',
		}),
		fetchData() {
			const methodId = this.$route.params.methodId;
			this.getInstallmentPlans({
				methodId,
				params: this.queryParams,
			});
		},
		updateUrlParams() {
			const query = cleanObject(cloneDeep(this.queryParams));
			this.$router.push({ query }).catch(() => {});
		},
		handleSearch() {
			this.queryParams.page = null;
			this.updateUrlParams();
		},
		handlePeriodStatus(event) {
			this.queryParams.page = null;
			this.queryParams.period_status = event.value;
			this.updateUrlParams();
		},
		handlePageChange(page) {
			this.queryParams.page = page;
			this.updateUrlParams();
		},
		handleFilterDateChange(event) {
			const { start, end } = event ? convertDateRangeToUTC(event) : {};

			this.queryParams.page = null;
			this.queryParams.started_at = start;
			this.queryParams.ended_at = end;
			this.updateUrlParams();
		},
		toggleFilter() {
			this.isShowFilter = !this.isShowFilter;
		},
		getSelectedValue(selectedValue = null, options = {}) {
			const statusOptionValues = Object.values(options);
			const findStatus = ({ value }) => value === selectedValue;
			return (
				statusOptionValues.find(findStatus) || {
					name: null,
					value: null,
				}
			);
		},
		resetFilter() {
			this.queryParams = {
				q: null,
				page: null,
				period_status: null,
				started_at: null,
				ended_at: null,
				r: null,
			};
			this.updateUrlParams();
		},
		redirectToPaymentSetting() {
			this.fetchData();
		},
		handleDeletePlan(id) {
			this.selectedPlanId = id;
			this.$refs['modal-remove'].open();
		},
		linkToKTCInstallmentPlanEdit(val) {
			this.$router.push({
				name: 'KTCInstallmentPlanEdit',
				params: {
					id: this.$route.params.id,
					methodId: this.$route.params.methodId,
					planId: val,
				},
			});
		},
	},
};
</script>

<style lang="scss" scoped>
	.filter-box {
		background-color: $color-gray-100;
	}

	.badge-order-status {
		padding: rem(7) rem(8);
	}

	::v-deep .table-custom {
		td {
			word-break: unset;

			&:hover {
				cursor: pointer;
			}
		}

		.interest-rate,
		.available-skus,
		.minimum-order,
		.maximum-order,
		.supplier-id {
			// .interest-rate-col, interest-rate-cel, .minimum-order-col, .minimum-order-cel,
			// .available-skus-col, .available-skus-cel,
			// .maximum-order-col, .maximum-order-cel
			// .supplier-id-col, .supplier-id-cel
			&-col, &-cel {
				text-align: right;
				word-break: unset;
			}
		}
	}

	// Set placeholder of filter by date range
	::v-deep .form-control-date {
		input[type="input"] {
			@include placeholder-black;
		}
	}
</style>
